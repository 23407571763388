import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import DatePicker from "vue2-datepicker"
import moment from 'moment'
import Loading from "@/components/widgets/Loading.vue"
export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      branchs: [],
      loading: false,
      drawerOpened: false,
      preview: null,
      showFileInput: true,
      inputType: 'file',
      fileError: '',
      file: null,
      form: {
        image: null,
        code: null,
        name: null,
        branch_id: null,
        installed_date: new Date(),
        description: null
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.showFileInput = true
        this.drawerOpened = n
        if (this.mode === 'update') {
          this.onInitMachine()
        }
      }
    }
  },
  mixins: [validationMixin],
  components: {
    DatePicker,
    Loading,
    Drawer
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      branch_id: { required }
    },
  },
  created() {
    this.onInitBranchs()
  },
  methods: {
    async onInitMachine() {
      try {
        const param = {
          _id: this.id
        }
        const result = await this.$store.dispatch('Machines/get', param, { root: true })
        if (result.status === 200) {
          this.formBuilder(result)
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        if (this.form.image) {
          formData.append('image', this.form.image)
        }
        formData.append('code', this.form.code)
        formData.append('name', this.form.name)
        formData.append('branch_id', this.form.branch_id)
        formData.append('installed_date', moment(this.form.installed_date).format('YYYY-MM-DD'))
        formData.append('description', this.form.description)

        let result = {}
        if (this.mode === 'create') {
          result = await this.$store.dispatch('Machines/create', formData, { root: true })
        } else {
          const body = {
            _id: this.id,
            formData: formData
          }
          result = await this.$store.dispatch('Machines/update', body, { root: true })
        }
        this.loading = false   
        setTimeout(() => {
          if (result.status === 201 || result.status === 200) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(result => {
              this.onCloseDrawer()
            })
          }
        }, 500)
      } catch (error) {
        this.loading = false 
        this.onExceptionHandler(error.response.data.message);
      }
    },        
    async onInitBranchs() {
      try {
        const result = await this.$store.dispatch('Branches/getAll', null, { root: true })
        if (result.status === 200) {
          this.formBuildOptions(result.data)
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    resetForm () {
      this.form.image = null
      this.form.code = null
      this.form.name = null
      this.form.branch_id = null
      this.form.installed_date = new Date()
      this.form.description = null

      this.$nextTick(() => {
        this.$v.$reset();
      })

      this.onResetFileInput()
    },
    formBuilder(obj) {
      this.form._id = obj.data?.uuid ?? ''
      this.form.code = obj.data?.code ?? ''
      this.form.name = obj.data?.name ?? ''
      this.form.branch_id = obj.data?.branch_id ?? ''
      this.form.installed_date = new Date(obj.data?.installed_date) ?? new Date()
      this.form.description = obj.data?.description ?? ''
      this.$v.form.$touch()
    },
    formBuildOptions (object) {
      for (let index = 0; index < object.length; index++) {
        const element = object[index]
        this.branchs.push({
          value: element.id,
          text: element.name
        })
      }
    },
    onFileHandler(e) {
      this.$refs.fileInput.click()
    },
    onResetFileInput() {
      this.inputType = 'text'      
      this.$nextTick(() => {
        this.fileError = ''
        this.file = null
        this.inputType = 'file'    
      })
    },
    onFileChange(e) {
      if (e.target.files.length > 0) { 
        this.fileError = ''
        const selectedFile = e.target.files[0]
        // File type validation (accept only image files)
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg']
        if (!allowedFileTypes.includes(selectedFile.type)) {
          this.fileError = 'ไฟล์รูปภาพไม่ถูกต้อง. กรุณาเลิอกไฟล์รูปภาพ.'
          return;
        } 
        
        this.onFIletoBase64(selectedFile).then(file => {
          this.file = file
          this.form.image = file
        }).catch(error => {
          this.onExceptionHandler(error.message)
        })
      }      
    },
    onFIletoBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    }
  }
}
