import Vue from "vue"
import LocationCreate from './location-create'
import LocationInfo from "./location-details"
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    LocationInfo,
    LocationCreate,
    Paginate
  },
  data: function() {
    return {
      loading: false,
      busy: false,
      sortBy: 'name',
      currentPage: 1, 
      currentData: {},
      perPage: 8,
      sortDesc: false,
      keyword: '',
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      create: {
        Id: null,
        mode: 'create',
        state: false
      },
      info: {
        Id: null,
        state: false
      },
      fields: [
        { 
          key: 'code', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          label: 'รหัสเครื่อง' 
        },
        { 
          key: 'name', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          label: 'เครื่องจักร' 
        },
        { 
          key: 'branch', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          label: 'สาขา' 
        },
        { 
          key: 'installed_date', 
          label: 'วันที่ติดตั้ง',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2'
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          class: 'text-gray-400 text-right text-xs-1/2' 
        }
      ],
      items: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData(1)
    })
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: { 
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.onConfirmDelete(id)
        }
      })
    },
    onConfirmDelete(id) {
      try {
        setTimeout(async() => {
          const param = {
            _id: id
          }
          const result = await this.$store.dispatch('Machines/delete', param, { root: true })      
          if (result.status === 204) {
            this.loading = false
  
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                '<span class="text-base text-left text-gray-500">ลบข้อมูลเรียบร้อยแล้ว</span>'+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                cancelButtonColor: '#dc4a38',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: "ตกลง",
                showCancelButton: false,
                showCloseButton: true,
            }).then(async confirm => {
              if(confirm.value){
                this.onInitData(1)
              }
            })
          }
        }, 500)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    oncreate() {
      this.create.Id = null
      this.create.mode = 'create'
      this.create.state = !this.create.state
    },
    onupdate(id) {
      this.create.Id = id
      this.create.mode = 'update'
      this.create.state = !this.create.state
    },
    onInfo(id) {
      this.info.Id = id
      this.info.state = !this.info.state
    },
    handleCreateEvent(event) {
      if (event.status) {
        this.create.state = false
        setTimeout(() => {
          const currentPage = this.currentPage
          this.onInitData(currentPage)
        }, 500)
      }
    },
    handleInfoEvent(event) {
      if (event.status) {
        this.info.state = false
      }
    },
    async onInitData(currentPage) {
      try {
        // this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const page = currentPage ? currentPage : 1
        const url = `${Vue.prototype.$host}/api/v1/machine?page=${page}&keyword=${this.keyword}`
        const param = {
          keyword: this.keyword,
          url: url
        }

        const result = await this.$store.dispatch('Machines/search', param, { root: true })
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        if (result) {
          this.currentData = result.data;
          this.items = result.data.data
          this.totalItem = result.data.total
        } else {
          this.items = result.data.data
          this.totalItem = result.data.total
        }
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}